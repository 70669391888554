'use strict';

define('vb/private/stateManagement/fragmentModuleViewModel',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/stateManagement/baseModuleViewModel',
], (Constants, Utils, BaseModuleViewModel) => {
  // whitelisted dynamic component layout properties that need to be migrated explicitly through to children layout
  // components if the outer container is a layout or a fragment inside of a layout!
  // $metadata is excluded from the layout list because it clashes with the $metadata that VB containers set up
  // @see http://slc16oke.us.oracle.com/dynamicui/12.0.0-rc.6/docs/apidocs/ContextOverview.html
  // In 2207 https://jira.oraclecorp.com/jira/browse/JET-49439 was fixed, but this manual migration of layout
  //  context properties is still required because the dynamic-bind-field CCA looks for $container for example.
  //  Other than dyn component authors should never be using these props directly in their fragment code!
  const LAYOUT_COMPONENT_CONTEXT_PROPS = ['$container', '$fields', '$metadataProvider', '$value'];
  const DYNAMIC_LAYOUT_CONTEXT_PROP_NAME = 'dynamicLayoutContext';
  const DYNAMIC_LAYOUT_CONTEXT_PROP_NAME_ALT = `$${DYNAMIC_LAYOUT_CONTEXT_PROP_NAME}`;

  /**
   * This class describes the oj-module view model for a fragment. The viewModel is a property of oj-module
   * moduleConfig and the value will be an instance of this class.
   */
  class FragmentModuleViewModel extends BaseModuleViewModel {
    /**
     * Use the fragment object in a closure so that it's hidden from the viewModel
     *
     * @param {Fragment} frag
     */
    constructor(frag) {
      super(frag);
      const fragment = frag;

      /**
       * oj-module viewModel lifecycle callback called when the vb-fragment view is inserted into the DOM. This method
       * might be called multiple times. This method is not async and so must not have any async calls that connecting
       * needs to wait on!
       * - after the View is created and inserted into the DOM,
       * - after the View is reconnected after being disconnected, or
       * - after a parent element, oj-module, with attached View is reconnected to the DOM.
       * @param domNodes An array of DOM nodes that represent the connected View
       */
      // eslint-disable-next-line class-methods-use-this
      this.connected = (domNodes) => {
        // We get the child nodes of ojModule so locate the ojModule node and observe that! During reconnection
        // domNodes may not be provided!
        if (domNodes && domNodes.length > 0) {
          const ojModuleNode = domNodes[0].parentNode; // generally a ojModule
          if (ojModuleNode && ojModuleNode.localName === 'oj-module') {
            const rootIS = Utils.vbModuleObserver.getIntersectionObserver();
            if (rootIS) {
              rootIS.observe(ojModuleNode);
            }
          }
        }
        // The secon check is a failsafe to prevent running when fragment definition is not present.
        // FA had a weird shell page navigation via a CCA event, where page was disposed and (fragment) definition
        // was being accessed (indirectly) from DOM.
        // Sometimes when page/fragment is disposed, the viewModel is unwound earlier than the DOM. So CCA connected
        // callbacks are called after a fragment has been disposed. While this automatically removes the definition as
        // well, the || check is added as a failsafe!
        if (fragment.lifecycleState !== Constants.ContainerState.DISPOSED || fragment.definition) {
          // we should not be calling a async method in the connected callback for the fragment ojModule viewModel but
          // in run() the initializePromise is guaranteed to be fulfilled by the time this method is called. And it's
          // fine to start the vbEnter cycle without waiting for it.
          fragment.run();
          fragment.fragmentLifecycleState = Constants.FragmentState.MODULE_CONNECTED;
        }
      };

      /**
       * oj-module viewModel lifecycle callback, called when the vb-fragment moduleConfig is disconnected. This method
       * is not async and so must not have any async calls that disconnecting needs to wait on!
       *
       * Because fragments may be disconnected from DOM but not removed we retain the scope in the store and only
       * dispose when the fragment is 'disposed'. Example when page navigates.
       * Note: https://jet.us.oracle.com/11.0.0/jsdocs/oj.ModuleViewModel.html
       */
      this.disconnected = () => {
        const parent = fragment.parent;
        // DT Designer uses a special ContainerState, where the parent page is left intact for the most part, just its
        // moduleConfig is refreshed. When this happens a new view / viewModel is created for page, causing fragment
        // CCAs to be recycled and the CCA callbacks to be fired. When disconnected is called the vbExit event cycle
        // runs async whereas new fragment CCA is re-connected in-sync causing issues where dispose (called async)
        // ends up removing the newly connected fragment.
        if (parent.lifecycleState === Constants.ContainerState.REFRESHING) {
          // set the lifecycleState for current container to refreshing so nested fragments can do a similar cleanup
          fragment.lifecycleState = parent.lifecycleState;
          fragment.deactivated = true;
          // also recursively disposes nested fragments
          fragment.dispose();
        }

        fragment.fragmentLifecycleState = Constants.FragmentState.MODULE_DISCONNECTED;

        // TODO: VBS-13900. it's problematic to call a async method in fragment ojmodule disconnected callback,
        //  because the callback is called synchronously and sometimes in rapid succession with connected. So for the
        //  same fragment connected callback can come in quickly after disconnected but before the vbExit async method
        //  is done! In such cases we might end up disposing fragment after connected!
        // const deactivateFragment = () => {
        //   // record a fragment deactivated state change
        //   StateMonitor.recordStateChange(StateMonitor.RuntimeState.FRAGMENT_DEACTIVATED);
        //   StateMonitor.recordStateChange(StateMonitor.RuntimeState.CONTAINER_DEACTIVATED, this);
        //   this.deactivated = true;
        //   this.dispose();
        // };
        //
        // this.invokeEvent(Constants.EXIT_EVENT).then(() => {
        //   // record a fragment deactivated state change
        //   deactivateFragment();
        // });
      };
    }

    getPropertyDescriptors(container) {
      const fragment = container;
      const descriptors = super.getPropertyDescriptors(container);

      /*
       * Migrate extra context properties from parent calling context (layout) to the fragment's view model. This is
       * done early intentionally because the outer context already has created it. These are needed by inner layout
       * components that might implicitly rely on the same in expressions, both in the view.html or in (layout) parts
       * of the layout descriptor .json.
       * Example, a dynamic-bind-field <-- inside a form template <-- wrapped in fragment <-- inside a dynamic-form.
       *
       * (1) View Usage, where fragment.html inside a dyn-form layout uses '$value'
       *    <oj-cx-es-smart-picker  label-hint="[[ $fragment.variables.labelHint ]]"
       *      selected-record-id="{{ $variables.dynamicLayoutContext.value[$fragment.variables.fieldName] }}"
       *
       * (2) Descriptor Usage. A fragment descriptor will never include a "layouts" that also uses the
       * component context properties in expressions. Usually these properties are used in layout descriptors
       * (layout.json and descriptor-overlay.json) in the sections pertaining to layouts, and not ever used in chains,
       * variables, events that are VB sections.
       *
       */
      LAYOUT_COMPONENT_CONTEXT_PROPS.forEach((prop) => {
        // input params can be either named 'dynamicLayoutContext' or '$dynamicLayoutContext'. DT uses former.
        const dynLayoutProp = (fragment.inputParams && (fragment.inputParams[DYNAMIC_LAYOUT_CONTEXT_PROP_NAME]
      || fragment.inputParams[DYNAMIC_LAYOUT_CONTEXT_PROP_NAME_ALT]));
        const dynamicLayoutContext = (dynLayoutProp && dynLayoutProp.value) || {};
        if (!descriptors[prop] && dynamicLayoutContext[prop]) {
          descriptors[prop] = {
            enumerable: true,
            configurable: true,
            get: () => dynamicLayoutContext[prop],
          };
        }
      });

      return descriptors;
    }
  }

  return FragmentModuleViewModel;
});

