'use strict';

define('vb/private/stateManagement/layoutExtension',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/layoutExtensionContext',
  'vb/private/stateManagement/layoutMixin',
  'vb/private/stateManagement/fragmentHolderExtensionMixin',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/stateManagement/baseModuleViewModel',
], (Mixin, ContainerExtension, LayoutExtensionContext, LayoutMixin, FragmentHolderExtensionMixin, Utils, Constants,
  BaseModuleViewModel) => {
  /**
   * LayoutExtension class
   */
  class LayoutExtension extends Mixin(ContainerExtension).with(FragmentHolderExtensionMixin, LayoutMixin) {
    constructor(extension, path, base, className = 'LayoutExtension') {
      // layout doesn't have a parent since it's not part of the container hierarchy
      super(extension, null, path, base, className);
    }

    static get extensionClass() {
      return LayoutExtension;
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * layout extension we want to use the same event prefix as layout
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'layout';
    }

    descriptorLoader(resourceLocator) {
      const resource = `${resourceLocator}-x.json`;
      return this.application.runtimeEnvironment.getExtensionTextResource(resource)
        .then((jsonContent) => Utils.parseJsonResource(jsonContent, resource));
    }

    functionsLoader(resourceLocator) {
      return super.functionsLoader(`${resourceLocator}-x`);
    }

    templateLoader(resourceLocator) {
      return super.templateLoader(`${resourceLocator}-x.html`);
    }

    /**
     * Returns a value that is appropriate to the option that is passed in.
     * @param option
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getCapability(option) {
      if (option === Constants.FragmentCapability.ALLOWS_SLOTS) {
        // fragment slots are disallowed in layouts
        return false;
      }
      return null;
    }

    /**
     * @type {String}
     */
    static get resourceSuffix() {
      return '-x.json';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getExtensionModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html.
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * Returns the model object for the layout container. The model contains all the accessible $ properties
     * of the layout container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     * The model is bound to the layout view by the JET dynamic component.
     * TODO: it's unclear if this method override returns a Map of contexts, similar to PageExtension, rather than
     * the new XYZModuleViewModel instance as the layout does. See comments on PageExtension for the reason
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *   $translations: {}
     *
     *   @todo: $extension.variables (tracked VBS-1793)
     * }
     *
     * @return {Promise<Object>} a promise that resolve with the model object.
     */
    getViewModel() {
      return Promise.all([
        this.load(), this.loadFunctionModule(), this.loadTemplate(),
      ])
        // TODO: it's unclear whether a new module viewModel instance needs to be created here or the one from base
        //  can be returned. We are providing separate layout data models to the layout components - one for the
        //  main (base) and for each extension. The dataModel includes the VB view model instance that's passed in to
        //  sub-CCAs like fragment by CCA framework. So vbBridge is needed
        .then(() => new BaseModuleViewModel(this));
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @type {LayoutExtensionContext}
     */
    static get ContextType() {
      return LayoutExtensionContext;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const map = {
        [Constants.LAYOUT_PREFIX]: this,
      };

      if (this.extensionId !== Constants.ExtensionNamespaces.BASE) {
        // for now expose global scope to layouts in extensions so fragments referenced from layouts can have access
        // to global scope.
        Object.assign(map, {
          [Constants.GLOBAL_PREFIX]: this.application,
        });
      } else {
        Object.assign(map, {
          [Constants.APPLICATION_PREFIX]: this.application,
        });
      }

      return map;
    }

    dispose() {
      // dispose fragments that the extension layout might have created
      this.disposeFragments();
      super.dispose();
    }

    /**
     * Used by event processing. For LayoutExtension containers, we start (and end) with the Layout itself.
     * @see FireCustomEventAction
     *
     * @overrides Container.getLeafContainer
     * @returns {Layout}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return this.base;
    }
  }

  return LayoutExtension;
});

